import AbstractAxiosInstance from "./AbstractAxiosInstance";
import CommunicationSettings from '../../CommunicationSettings';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { PresetOutDTO, PresetInDTO, PresetsInDTO, DeviceConfig } from "@/interfaces/preset";
import { ModelOutDTO, ModelInDTO, ModelIn, ValidateModelOutDTO } from "@/interfaces/model";
import {DeviceInDTO, DeviceSearchParamsDTO, DeviceSearchInDTO, DeviceIn} from "@/interfaces/device";
import IDeviceMappingAxiosInstance from "../IDeviceMappingAxiosInstance";
import store from "@/store/store";
import { DeviceConfigurationJsonDTO } from "@/interfaces/IPlatformController";

class DeviceMappingAxiosInstance extends AbstractAxiosInstance implements IDeviceMappingAxiosInstance {
  constructor() {
    super();
    this.updateBaseUrl();
    this.setTokenAuthenticationInterceptor();
  }

  public updateBaseUrl() {
    this.setBaseUrl(CommunicationSettings.deviceMappingApiUrl());
  }
  
  public createPreset(data: PresetOutDTO, params?: any): Promise<void> {
    const config: AxiosRequestConfig = {
      params,
      timeout: 5 * 60 * 1000 // 5 minutes
    };

    return this.post('/api/v1/presets', data, config);
  }

  public fetchPresets(params?: any): Promise<PresetsInDTO> {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.get('/api/v1/presets', config);
  }

  public fetchPreset(presetId: String, params?: any): Promise<PresetInDTO> {
    const config: AxiosRequestConfig = {
      params,
    };
    let res = this.get(`/api/v1/presets/${presetId}`, config);
    return res as Promise<PresetInDTO>;
  }

  public fetchDeviceConfig(presetId: String, params?: any): Promise<DeviceConfigurationJsonDTO> {
    const config: AxiosRequestConfig = {
      params,
    };
    let res = this.get(`/api/v1/presets/${presetId}/deviceConfiguration`, config);
    return res as Promise<DeviceConfigurationJsonDTO>;
  }

  public createModel(data: ModelOutDTO, params?: any): Promise<ModelIn> {
    const config: AxiosRequestConfig = {
      params,
      timeout: 5 * 60 * 1000 // 5 minutes
    };

    return this.post('/api/v1/models', data, config);
  }

  public fetchModels(params?: any): Promise<ModelInDTO> {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.get('/api/v1/models', config);
  }

  public fetchModelsByName(params ?: any): Promise<ModelInDTO> {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.get('/api/v1/models/names', config);
  }

  public setDeviceConfiguration(deviceId: String, presetId: String): Promise<void> {
    return this.put(`/api/v1/devices/${deviceId}`, { presetId });
  }

  public getDevices(params?: any): Promise<DeviceInDTO> {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.getAxiosInstance()
      .get<DeviceInDTO>(`/api/v1/devices`, config)
      .then( (t: AxiosResponse<DeviceInDTO>) => { 
        return { serverTimestamp: (new Date(t.headers.date)).valueOf()  ,  ...t.data }; 
      });

  }
  
  public searchDevices(params?: DeviceSearchParamsDTO): Promise<DeviceSearchInDTO> {
    const config: AxiosRequestConfig = {
      ...params,
    };

    return this.getAxiosInstance()
      .post<DeviceSearchInDTO>(`/api/v1/devices/search`, config, params)
      .then( (t: AxiosResponse<DeviceSearchInDTO>) => {
        return { serverTimestamp: (new Date(t.headers.date)).valueOf(),  ...t.data }; 
      });
  }

  public getDevice(deviceId: String, params?: any): Promise<{ value: DeviceIn }> {
    const config: AxiosRequestConfig = {
      params,
    };
    return this.getAxiosInstance()
      .get< { value: DeviceIn }>(`/api/v1/devices/${deviceId}`, config)
      .then( (t: AxiosResponse<{ value: DeviceIn }>) => { 
        return { serverTimestamp: (new Date(t.headers.date)).valueOf(),  ...t.data }; 
      });
  }

  public deleteDevice(deviceId: String, params?: any): Promise<any> {
    const config: AxiosRequestConfig = {
      params,
    };
    let res = this.delete(`/api/v1/devices/${deviceId}`, config);
    //return res as Promise<DeviceInDTO>;
    return res as Promise<any>;
  }

  public patchDeviceAttributes(deviceId: String, fieldValue: { [s:string]: any }) : Promise<void> {
    return this.patch(`/api/v1/devices/${deviceId}`, fieldValue);
  }

  public getModelVersions(modelName: String, params?: any ): Promise<{data: string[]}> {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.get(`/api/v1/models/${modelName}/versions`, config);
  }

  public getModelById(modelId: String, params?: any): Promise<any> {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.get(`/api/v1/models/${modelId}`, config);
  }

  public deleteModelById(modelId: String, params?: any): Promise<void> {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.delete(`/api/v1/models/${modelId}`, config);
  }

  public updateModel(modelId: String, model: ModelOutDTO,  params?: any): Promise<any> {
    const config: AxiosRequestConfig = {
      params,
      timeout: 5 * 60 * 1000 // 5 minutes
    };

    return this.put(`/api/v1/models/${modelId}`, model, config);
  }

  public deletePresetById(presetId: String, params?: any): Promise<void> {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.delete(`/api/v1/presets/${presetId}`, config);
  }

  public validateModel(model: ValidateModelOutDTO, params?: any) : Promise<any> {
    const config: AxiosRequestConfig = {
      params,
    };

    return this.post(`/api/v1/models/validate`, model, config);
  }

  public updateMappingsSuborgVisibility(params ){
    const config={
      data:params.data
    }
    const actualOrg = store.getters['permission/getOrganizations'].selectedSubOrganization.resourceId
    return this.put(`/api/v1/mappings/${params.mapping.id}/subOrgsEnabledToView`,config,{params:{organization:actualOrg}});
  }

  public updateModelsSuborgVisibility(params ){
    const actualOrg = store.getters['permission/getOrganizations'].selectedSubOrganization.resourceId
    const config={
      data:params.data
    }
    // here we send configuration(JSON) and params(query), you must respect this order
    return this.put(`/api/v1/models/name/${params.model.name}/subOrgsEnabledToView`,config,{params:{organization:actualOrg}})
  }
}

export default new DeviceMappingAxiosInstance();
