//import Keycloak from 'keycloak-js'
import store from '../'
import mainStore from '@/store/store';
import { CommunicationSettings } from "@/corvina-connect";
import BasicAxiosInstance from '@/communication/axios/implementation/BasicAxiosInstance';

export default async (next, roles) => {

  const realm = await CommunicationSettings.realm();

  global.$keycloak = new global.Keycloak({
    url: CommunicationSettings.authApiUrl(),
    realm,
    clientId: 'corvina-web-portal',
  })

  let keycloakAuth = global.$keycloak
  console.log(window.location.href)
  let authenticated = await keycloakAuth.init({ onLoad: 'login-required', responseMode: 'fragment', checkLoginIframe: false })
    .catch((error) => {
      console.error("KEYCLOAK INIT ERROR: " + error)
    })
  if (!authenticated) {
    window.location.reload()
  }
  store.dispatch('authLogin', keycloakAuth)
  if (roles) {
    if (keycloakAuth.hasResourceRole(roles[0])) {
      next()
    }
    else {
      next({ name: 'Unauthorized' })
    }
  }
  else {
    //next()
  }
  setInterval(function () {
    console.log("UPDATE TOKEN...")
    keycloakAuth.updateToken(70)
      .success((refreshed) => {
        if (refreshed) {
          store.dispatch('authLogin', keycloakAuth)
          
          const orgs = mainStore.getters['permission/getOrganizations'];
          BasicAxiosInstance.getAxiosInstanceToken(orgs.selectedOrganization);
        }
        else {
          console.log('Token not refreshed, valid for ' + Math.round(keycloakAuth.tokenParsed.exp + keycloakAuth.timeSkew - new Date().getTime() / 1000) + ' seconds')
        }
      }).error(function () {
        console.error('Failed to refresh token')
      })
  }, 60000)
}
