// @ts-ignore : ignore BrandData import since it needs to be resolved at runtime
import { defaultLocale } from "BrandData";

export function getDomain() : string|null {
  const dp = document.location.hostname.split('.')
  let domain = null;
  if(dp.length >= 2) {
      domain = '.' + dp[dp.length-2] + '.' + dp[dp.length-1];
  }
  return domain;
}

export function setCC2LocaleCookie(locale: string) {
  const domain = getDomain();
  if(domain) {
    // domain and path must be set. ".domain": the dot is mandatory to share between subdomains.
    document.cookie = `cc2locale=${locale};domain=${domain};path=/;`
  }
}

export const fixShortLocale = (locale: string) => {
  if (locale.length < 3) {
    switch(locale) {
      case 'en':
        return defaultLocale;
      default:
        return `${locale}-${locale.toUpperCase()}`        
    }
  }
  return locale;
}