import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import IAxiosInstance from '../IAxiosInstance';
import { ReplyRequestParams } from '../model/ReplyRequestParams';
import store from "@/store/store";

export default abstract class AbstractAxiosInstance implements IAxiosInstance {

  private axiosInstance = axios.create({
    timeout: 30000,
  });

  public getAxiosInstance(): AxiosInstance {
    return this.axiosInstance;
  }

  public ejectRequestInterceptor(key: number): void {
    this.axiosInstance.interceptors.request.eject(key);
  }

  public setRequestInterceptor(onFulfilled?: (value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>, onRejected?: (error: any) => any): number {
    return this.axiosInstance.interceptors.request.use(onFulfilled, onRejected);
  }

  public setResponseInterceptor(i : (res: AxiosResponse) => any): number {
    return this.axiosInstance.interceptors.response.use(i);
  }

  public setTokenAuthenticationInterceptor() {
    this.setRequestInterceptor(async function (config: AxiosRequestConfig) {
      const token = store.getters['permission/getAccessToken'];
      if (token) // avoid settings undefined Authorization (this can occur during early stages of login for public apis)
        config.headers['Authorization'] = token;
      return config;
    })
  }

  public ejectResponseInterceptor(key: number): void {
    this.axiosInstance.interceptors.response.eject(key);
  }

  public replayRequest(params: ReplyRequestParams) {
    axios(params.initialRequest).then((response) => {
      params.resolve(response);
    }).catch((reason) => {
      params.reject(reason);
    });
  }

  public setBaseUrl(baseURL: string): void {
    this.axiosInstance.defaults.baseURL = baseURL;
  }

  public updateBaseUrl(): void {}

  public getBaseUrl(): string {
    return this.axiosInstance.defaults.baseURL;
  }

  public delete(url: string, config?: AxiosRequestConfig): Promise<any> {
    return this.axiosInstance.delete(url, config);
  }

  public get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.get<T>(url, config).then((t: AxiosResponse<T>) => t.data);
  }

  public patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.patch<T>(url, data, config).then((t: AxiosResponse<T>) => t.data);
  }

  public post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.post<T>(url, data, config).then( (t: AxiosResponse<T>) => t.data);
  }

  public put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.put<T>(url, data, config).then((t: AxiosResponse<T>) => t.data);
  }
}