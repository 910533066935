import AbstractAxiosInstance from './AbstractAxiosInstance';
import CommunicationSettings from '../../CommunicationSettings';
import BasicAxiosInstance from './BasicAxiosInstance';
import { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import {DeviceLogsDTO, UserPreferenceApplicationDTO, VPNDeviceDTO, VPNDeviceEndpoint, VPNJsonActionDTO, VpnExecuteActionOutDTO} from '@/interfaces/VPNDevice';
import store from '@/store/store';
import {ActionProfileRequestDTO, ActionDTO, ActionFullDTO, VPNEndpointDTO, VPNGatewayDTO, VPNProfile} from "@/interfaces/VPNDevice";
import { ClientOs } from '../../../utils/Features';
import { ActivateDeviceLicenseInDTO } from '@/interfaces/devicelicence';

class VPNAxiosInstance extends AbstractAxiosInstance {
  constructor() {
    super();
    this.setTokenAuthenticationInterceptor();
  }

  // ping timer
  static aliveTimer: any;

  private formatClientOS(clientOs: string) {
    let os = ''
    switch(clientOs) {
      case ClientOs.LINUX:
        os = 'Linux';
        break;
      case ClientOs.MAC:
        os = 'Mac';
        break;
      case ClientOs.WINDOWS:
        os = 'Win';
        break;
    }
    return os;
  }
  
  async fetchEndpoints(orgResId: string, data): Promise<void> {
    return this.post('/api/v1/endpoints');
  }

  async fetchDomainGateways(orgResourceId: any, filter): Promise< { serverTimestamp: number, content: VPNDeviceDTO[] } > {
    const config = {
      params: {
        ...filter
      }
    };

    return this.getAxiosInstance()
      .get(`/api/v1/gateways/${orgResourceId}`, config)
      .then( (t) => {
        return { serverTimestamp: (new Date(t.headers.date)).valueOf(),  ...t.data }; 
      });
  }

  async fetchGateway(orgResourceId: any, deviceLabel: string): Promise<VPNGatewayDTO> {
    return this.get(`/api/v1/gateways/${deviceLabel}/${orgResourceId}`);
  }

  async postEndpoint(newEndpoint: VPNEndpointDTO) {
    return this.post('/api/v1/endpoints/', newEndpoint)
  }

  async postGatewayPosition(gateway: string, domain: string, latitude: string, longitude: string ) {
    return this.post(`/api/v1/gateways/${gateway}/${domain}/position?latitude=${latitude}&longitude=${longitude}`);
  }

  async postGatewayNetwork(gateway: string, domain: string, data:  { gateway_disable_virtual_ip, gateway_virtualnetwork_size} ) {
    return this.post(`/api/v1/gateways/${gateway}/${domain}/network`, data);
  }

  async deleteEndpoint({ gateway, domain, endpoint } : { gateway: string, domain: string, endpoint: VPNDeviceEndpoint }) {
    return this.delete(`/api/v1/endpoints/${endpoint.name}/${gateway}/${domain}`);
  }

  async credentialsRequest(data: { name; domain}) {
    return this.post(`/api/v1/credentials/request`, data);
  }
  
  async fetchApplicationProfiles(orgResourceId: any, filter: any, name:string|null=null): Promise<any> {
    const config = {
      params: {
        ...filter
      }
    }
    const nameSuffix = name ? `/${name}` : ''
    return this.get(`/api/v1/domains/${orgResourceId}/actionProfiles${nameSuffix}`, config);
  }
  
  async fetchApplicationProfileDetails(orgResourceId: any, applicationProfile: any): Promise<any> {
    return this.get(`/api/v1/domains/${orgResourceId}/actionProfiles/${applicationProfile}`);
  }

  // async login(data: { name; domain}) {
  //   return this.post(`/api/v1/users/${encodeURIComponent(data.name)}/${data.domain}/login`, {}, {withCredentials: true});
  // }

  async logout(data: { originalUrl: string}) {
    fetch((new URL('/manage/access/logout', data.originalUrl)).toString(), {
        "method": "POST",
        "credentials": "include"
    });
  }

  async login(data: { url: string; loginPath: string; name: string; password: string; domain: string, originalUrl: string, originalLoginPath: string}) {
    const localVarFormParams = new URLSearchParams();
    localVarFormParams.set('emi_username', `${data.name}/${data.domain}`);
    localVarFormParams.set('emi_password', data.password);
    localVarFormParams.set('ajax', 'true');

    // this call must be done in the frontend to allow setting cookies properly
    // PS: The request will fail because of cors (the login page is not supporting cross-origin request)
    // Nevertheless the cookies will be properly set
    await Promise.all([
      (async () => {
        try { 
          // set cookies for the original vpn domain (used by actions)
          await axios.post(
            `${new URL(data.originalLoginPath, data.originalUrl).toString()}?_ts=${Date.now()}`,
            localVarFormParams.toString(), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            withCredentials: true,
            maxRedirects: 0,
            validateStatus: (status: number) => { return status == 200 || status == 302; }
          })
          console.log(`Completed login attempt with ${data.originalLoginPath}`)
        } catch(err) {
          // cors error is expected contacting a different domain. A proxy is required to fix this behavior
        }    
      })(),
      (async () => {
        try { 
          // set cookies for the proxied vpn domain (use for connectio)
          await axios.post(
            `${new URL(data.loginPath, data.url).toString()}?_ts=${Date.now()}`,
            localVarFormParams.toString(), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            withCredentials: true,
            maxRedirects: 0,
            validateStatus: (status: number) => { return status == 200 || status == 302; }
          })
          console.log(`Completed login attempt with ${data.loginPath}`)
        } catch(err) {
          // cors error is expected contacting a different domain. A proxy is required to fix this behavior
        }    
      })()

    ])

    // star session keep alive timer
    if (VPNAxiosInstance.aliveTimer) {
      clearInterval(VPNAxiosInstance.aliveTimer);
    }
    VPNAxiosInstance.aliveTimer = setInterval( 
      () => fetch(`${new URL('/manage/commands/commands.access.client.ping', data.originalUrl).toString()}?_=${Date.now()}`, {
        "method": "GET",
        "credentials": "include"
      }), 180 * 1000);
  }

  async createApplicationProfile(orgResourceId: string, applicationProfile: ActionProfileRequestDTO) {
    return this.post(`/api/v1/domains/${orgResourceId}/actionProfiles`, applicationProfile);
  }

  async editApplicationProfile(orgResourceId: any, applicationProfile: ActionProfileRequestDTO) {
    return this.post(`/api/v1/domains/${orgResourceId}/actionProfiles`, applicationProfile);
  }

  async deleteApplicationProfile(orgResourceId: any, applicationProfile) {
    return this.delete(`/api/v1/domains/${orgResourceId}/actionProfiles/${applicationProfile}`);
  }
  
  async fetchApplications(orgResourceId: any, filter: any): Promise<ActionDTO> {
    const config = {
      params: {
        ...filter
      }
    };
    return this.get(`/api/v1/domains/${orgResourceId}/actions`, config);
  }
  
  async fetchApplicationDetails(orgResourceId: any, appName: string): Promise<ActionFullDTO> {
    return this.get(`/api/v1/domains/${orgResourceId}/actions/${appName}`);
  }

  async createApplication(orgResourceId: any, data) {
    return this.post(`/api/v1/domains/${orgResourceId}/actions`, data)
  }

  async editApplication(orgResourceId: any, data: ActionFullDTO):Promise<ActionFullDTO> {
    return this.put(`/api/v1/domains/${orgResourceId}/actions`, data)
  }
  
  async deleteApplication(orgResourceId: any, actionName: string) {
    return this.delete(`/api/v1/domains/${orgResourceId}/actions/${actionName}`)
  }

  public async fetchUserPreferences(username: string): Promise<UserPreferenceApplicationDTO>
  {
    const selectedOrganization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    return await this.get( `/api/v1/preferences/applications/${selectedOrganization.resourceId}/${username}`, {params: {page: 0, pageSize: 200}} );
  }

  public async addApplicationToUserPreferences(username: string, gatewayName: string, endpointName: string, applicationProfile: string, applicationName:string): Promise<any>
  {
    const selectedOrganization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    return await this.post( `/api/v1/preferences/applications/${selectedOrganization.resourceId}/${username}/${gatewayName}/${endpointName}/${applicationProfile}/${applicationName}`);
  }

  public async deleteApplicationFromUserPreferences(vpnUserpreferenceId: number): Promise<any>
  {
    const selectedOrganization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    return await this.delete( `/api/v1/preferences/applications/${selectedOrganization.resourceId}/${vpnUserpreferenceId}`);
  }

  public async executeAction(gatewayName: string, endpointName: string, actionName: string, clientOs: ClientOs) : Promise<VpnExecuteActionOutDTO> {
    return await this.get( `/api/v1/executeAction`, {params: {gatewayName, endpointName, actionName, clientOs: this.formatClientOS(clientOs)} })
  }

  public async executeActionOnDomain(orgResourceId: string, gatewayName: string, endpointName: string, actionName: string, clientOs: ClientOs) : Promise<VpnExecuteActionOutDTO> {
    return await this.get( `/api/v1/executeAction/${orgResourceId}`, {params: {gatewayName, endpointName, actionName, clientOs: this.formatClientOS(clientOs)} })
  }

  public async setApplicationPassword( value: {type: string, password: string} ) : Promise<any> {
    return await this.post( `/api/v1/setApplicationPassword`, value  )
  }

  public async connectAppRedirect(url, data) {
    return await this.post(url, data)
  }

  public async fetchDeviceLogs(gatewayName: string, orgResourceId: string, fromTimestamp: number, toTimestamp: number, limit: number = 1000): Promise<DeviceLogsDTO[]> {
    return await this.get( `/api/v1/gateways/${gatewayName}/${orgResourceId}/logs`, {params: {showSystem: false, fromTimestamp, toTimestamp, limit}} );
  }

  async fetchDeviceJsonActions(deviceId: string, clientOs: ClientOs) :  Promise<VPNJsonActionDTO>{
    const organizations = store.getters['permission/getOrganizations'];
    const orgResourceId = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    return await this.get(`/api/v1/connections/${orgResourceId}/${deviceId}/jsonActions?clientOs=${this.formatClientOS(clientOs)}`);
  }

  async connectToAllGatewayEndpoints(
    gatewayId:string,
    clientOs: ClientOs = ClientOs.UNKNOWN,
    otp: string
  ){
    const organizations = store.getters['permission/getOrganizations'];
    const orgResourceId = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    const config = {
      params: {
        onlyGateway: false,
        otp
      }
    };
    return await this.get(`/api/v1/connections/${orgResourceId}/${gatewayId}/connect?clientOs=${this.formatClientOS(clientOs)}`, config);
  }

  async connectToGatewayEndpoint(
    gatewayId: string, 
    endpointId: string, 
    clientOs: ClientOs = ClientOs.UNKNOWN,
    otp: string
  ){
    const organizations = store.getters['permission/getOrganizations'];
    const orgResourceId = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    const config = {
      params: {
        onlyGateway: false,
        otp
      }
    };
    return await this.get(`/api/v1/connections/${orgResourceId}/${gatewayId}/endpoints/${endpointId}/connect?clientOs=${this.formatClientOS(clientOs)}`, config);
  }

  async disconnectFromAllGatewayEndpoints(gatewayId:string){
    const organizations = store.getters['permission/getOrganizations'];
    const orgResourceId = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    const config = {
      params: {
        onlyGateway: false,
      }
    };
    return await this.get(`/api/v1/connections/${orgResourceId}/${gatewayId}/disconnect`, config);
  }

  async disconnectFromGatewayEndpoint(gatewayId : string, endpointId: string){
    const organizations = store.getters['permission/getOrganizations'];
    const orgResourceId = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    const config = {
      params: {
        onlyGateway: false,
      }
    };
    return await this.get(`/api/v1/connections/${orgResourceId}/${gatewayId}/endpoints/${endpointId}/disconnect`, config);
  }

  async resetVpnConnection(gatewayName: string){
    const organizations = store.getters['permission/getOrganizations'];
    const orgResourceId = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    return await this.get(`/api/v1/gateways/${gatewayName}/${orgResourceId}/resetVpnConnection`);
  }
  
  async getCompanionAppInfo() {
    return await this.get(`/api/v1/companionApp/info`);
  }

  async checkOTPPassword(gatewayId: string, otp: string) {
    const organizations = store.getters['permission/getOrganizations'];
    const orgResourceId = organizations.selectedSubOrganization && organizations.selectedSubOrganization.resourceId;
    const config = { params: { otp } };
    return await this.get(`/api/v1/connections/${orgResourceId}/${gatewayId}/checkotp`, config);
  }
}

export default new VPNAxiosInstance();