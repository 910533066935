import AbstractAxiosInstance from "./AbstractAxiosInstance";
import CommunicationSettings from '../../CommunicationSettings';
import { AxiosRequestConfig } from 'axios';
import ILicenseManagerAxiosInstance from "../ILicenseManagerAxiosInstance";
import {
  ActivateDeviceLicenseOutDTO,
  ActivateDeviceLicenseInDTO,
  ActivationLicenseInDTO,
  ActivateDeviceLicenseVPNInDTO,
  VpnUsageHistoryAggregatedDTO
} from "@/interfaces/devicelicence";
import store from "@/store/store";
import { PaginationRestDTO} from "@/interfaces/commons/pagination";
import { ConsumptionEntry, ConsumptionHistory, ConsumptionsTable } from "@/interfaces/consumptionsTable";
import { ProductResourceType } from "../model/Product";

export interface ConsumptionsFilter {
  aggregated?: boolean,
  deviceLabel?: string | string[],
  filterDate?: { end: string, start: string },
  page?: number,
  pageSize?: number,
  resourceType?: ProductResourceType,
  includeSubOrgs?: boolean,
}

class LicenseManagerAxiosInstance extends AbstractAxiosInstance implements ILicenseManagerAxiosInstance {
  constructor() {
    super();
    this.updateBaseUrl();
    this.setTokenAuthenticationInterceptor();
  }

  public updateBaseUrl() {
    this.setBaseUrl(CommunicationSettings.licenseManagerApiUrl());
  }
  
  public activateDevice(data: ActivateDeviceLicenseOutDTO): Promise<ActivateDeviceLicenseInDTO> {
    return this.post('/api/v1/deviceLicenses/activate', data);
  }

  public checkActivationCode(activationKey: string): Promise<ActivationLicenseInDTO> {
    return this.get(`/api/v1/deviceLicenses/device?serialNumber=&activationKey=${activationKey}`);
  }

  public relabelDevice(orgResourceId: String, deviceId: String, newLabel: String) : Promise<any> {
    return this.put('/api/v1/deviceLicenses/label', { logicalId: deviceId, label: newLabel, orgResourceId: orgResourceId })
  }

  public validateLicense(licenseCode: string): Promise<any> {
    return this.get(`/api/v1/licenses/validate?licenseCode=${licenseCode}`);
  }

  public deleteDeviceLicense(orgResourceId: String, deviceLicenseId: String): Promise<any> {
    return this.delete(`/api/v1/deviceLicenses/${deviceLicenseId}?orgResourceId=${orgResourceId}`);
  }

  public fetchDeviceLicense(orgResourceId: String, deviceId: String): Promise<any> {
    return this.get(`/api/v1/deviceLicenses?orgResourceId=${orgResourceId}&filter=${deviceId}`);
  }

  public fetchDeviceLicenses(orgResourceId: String, filter): Promise<PaginationRestDTO<ActivateDeviceLicenseInDTO>> {
    const config = {
      params: {
        ...filter
      }
    };
    return this.get(`/api/v1/deviceLicenses?orgResourceId=${orgResourceId}`, config);
  }

  async vpnActivate(data: ActivateDeviceLicenseVPNInDTO): Promise<ActivateDeviceLicenseInDTO> {
    return this.post(`/api/v1/deviceLicenses/vpn/activate`, data);
  }

  async vpnAutorenew(data: ActivateDeviceLicenseVPNInDTO): Promise<ActivateDeviceLicenseInDTO> {
    return this.put(`/api/v1/deviceLicenses/vpn/autorenew`, data);
  }
  private dateToTs(filterDate: {start: string, end: string}) {
    let filterTs =  {start: "", end: ""}
    if(filterDate && filterDate.start && filterDate.end) {
      filterTs.start = new Date(filterDate.start).getTime().toString();
      filterTs.end = (new Date(filterDate.end).getTime() + (60 * 60 * 24 * 1000)).toString()
    }
    return filterTs;
  }
  
  public async fetchConsumptionTable(filter: ConsumptionsFilter) : Promise<ConsumptionHistory | ConsumptionEntry>{
    const organization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    const organizationId = organization && organization.resourceId;
    const filterTs = this.dateToTs(filter.filterDate);
    return await this.get('/api/v1/subscriptions/resource/'+filter.resourceType+'/journal'+ (filter.aggregated? '/sum' :''),{params:{orgResourceId: organizationId, fromDate:filterTs.start, toDate:filterTs.end,pageSize: filter.pageSize, page: filter.page, deviceLabel: filter.deviceLabel, includeSubOrgs: filter.includeSubOrgs }})
  }
  
  public async fetchConsumptionGraph(filter: ConsumptionsFilter) : Promise<ConsumptionHistory>{
    const organization = store.getters['permission/getOrganizations'].selectedSubOrganization;
    const organizationId = organization && organization.resourceId;
    const filterTs = this.dateToTs(filter.filterDate);
    return await this.get('/api/v1/subscriptions/history',{params:{resourceId: filter.resourceType, orgResourceId: organizationId, fromDate:filterTs.start, toDate:filterTs.end,pageSize: filter.pageSize, page: filter.page, deviceLabel: filter.deviceLabel }})
  }
}

export default new LicenseManagerAxiosInstance();