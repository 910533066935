import { $keycloak } from './keycloak.js' 
import axios from 'axios';
import { OrganizationLoginInfoDTO } from '../interfaces/organization.js';
import { getCookie } from 'tiny-cookie'
import { AppFeatures } from '@/utils/Features'

export default class CommunicationSettings
{
    /* Corvina settings */
    /*
    "authApiUrl" : "https://auth.corvina.cloud/auth"
    */

    static baseHostnameCache: string;
    static realmCache: string;
    static corvinaAccountsRealmCache: string;
    private static organizationCache: string;

    static async realm(): Promise<string> {
        if (!CommunicationSettings.realmCache) {
            let appPos = location.hostname.indexOf(".app.")
            if (appPos >= 0) {
                CommunicationSettings.organizationCache = location.hostname.slice(0, appPos);
                try {
                    const loginInfo = await this.getLoginInfo(CommunicationSettings.organizationCache);
                    CommunicationSettings.realmCache = loginInfo.realmName;
                } catch(error) {
                    CommunicationSettings.realmCache = null;
                    throw error;
                }
            } else {
                // default realm is exor
                CommunicationSettings.realmCache = getCookie('ccdeforg') || 'exor';
            }
        }
        return CommunicationSettings.realmCache;
    }

    static corvinaAccountsRealm() {
        if (!CommunicationSettings.corvinaAccountsRealmCache) {
            CommunicationSettings.corvinaAccountsRealmCache = getCookie('ccaccountsrealm') || 'CorvinaAccounts';
        }
        return CommunicationSettings.corvinaAccountsRealmCache;
    }

    static async getLoginInfo(organizationHostname: string): Promise<OrganizationLoginInfoDTO> {
        const config = {
            params: {
                hostname: organizationHostname
            },
            timeout: 30000,
        };

        const endpoint = `${this.corvinaCoreApiUrl()}/api/v1/organizations/loginInfo`;

        try {
            const response = await axios.get<OrganizationLoginInfoDTO>(endpoint, config);
            return response.data;
        } catch(error) {
            throw error;
        }
    }

    static organization(): string {
        if (!CommunicationSettings.organizationCache) {
            this.realm(); // will compute it
        }
        return CommunicationSettings.organizationCache;
    }

    static baseHostname() : string {
        if (!CommunicationSettings.baseHostnameCache) {
            let appPos = location.hostname.indexOf("app.")
            if (appPos >= 0) {
                CommunicationSettings.baseHostnameCache = location.hostname.slice(appPos + 4);
                if (location.port.length && ( location.hostname != "localhost" && location.hostname != "127.0.0.1" && location.hostname != "0.0.0.0")) {
                    // TODO: Removed for tests, but it should be provided by configuration and not computed from hostname
                    CommunicationSettings.baseHostnameCache += `:${location.port}`;
                }
            } else {
                CommunicationSettings.baseHostnameCache =  "corvina.cloud";
            }
        }
        return CommunicationSettings.baseHostnameCache;
    }

    // Returns the url entered preferred hostname for organization
    static orgHostname() : string {
        let appPos = location.hostname.indexOf(".app.")
        if (appPos >= 0) {
            return location.hostname.slice(0, appPos);
        }
        return undefined;
    }

    static themeApiUrl() : string {
        return "https://app." + CommunicationSettings.baseHostname() + "/svc/core/api/v1/organizations"
    }

    static licensesApiUrl() : string {
        return "https://app." + CommunicationSettings.baseHostname() + "/svc/license/api/v1"
    }

    static limitsApiUrl() : string {
        return "https://app." + CommunicationSettings.baseHostname() + "/svc/license/api/v1/limits"
    }

    static alarmsApiUrl() : string {
        return "https://app." + CommunicationSettings.baseHostname() + "/svc/platform/api/v1/alarms"
    }

    static notificationApiUrl() : string {
        return "https://app." + CommunicationSettings.baseHostname() + "/svc/platform/api/v1/notifications"
    }

    static authApiUrl() : string {
        return /* localStorage.getItem(CommunicationSettings.baseHostname() + '/authURL') || */ "https://auth." + CommunicationSettings.baseHostname() + "/auth"
    }

    static licenseManagerApiUrl() : string {
        return /* localStorage.getItem(CommunicationSettings.baseHostname() + '/licenseURL') || */ `https://app.${CommunicationSettings.baseHostname()}/svc/license`
    }
    
    static deviceMappingApiUrl() : string {
        return /* localStorage.getItem(CommunicationSettings.baseHostname() + '/mappingsURL') || */ `https://app.${CommunicationSettings.baseHostname()}/svc/mappings`
    }

    static dashboardApiUrl() : string {
        return /* localStorage.getItem(CommunicationSettings.baseHostname() + '/dashboardURL') || */ `https://app.${CommunicationSettings.baseHostname()}/svc/dashboard`
    }

    static corvinaCoreApiUrl() : string {
        return /* localStorage.getItem(CommunicationSettings.baseHostname() + '/core') || */ `https://app.${CommunicationSettings.baseHostname()}/svc/core`
    }

    static platformControllerUrl() : string {
        return "https://app." + CommunicationSettings.baseHostname() + "/svc/platform/api/v1";
    }

    static wsPlatformControllerUrl() : string {
        return "wss://app." + CommunicationSettings.baseHostname() + "/svc/platform/api/v1";
    }

    static keycloak(): any  { 
        return  $keycloak() 
    }

    static vpnProxyApiUrl() {
        return "https://app." + CommunicationSettings.baseHostname() + "/svc/vpn";
    }

    static corvinaVpnProxyApiUrl() {
        return "https://app." + CommunicationSettings.baseHostname() + "/svc/vpn2";
    }

    static auditApiUrl() {
        return "https://app." + CommunicationSettings.baseHostname() + "/svc/audit/api/v1/eventLogs";
    }
}